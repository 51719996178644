<template>
  <router-link :to="{ name: 'product', params: { productId: product._id } }" class="search-item">
    <div class="search-item__image-wrapper" :style="{ 'background-image': imageUrl ? 'none' : `url(${defaultImage})` }">
      <img v-if="imageUrl" :src="imageUrl" :alt="product.name" class="search-item__image" />
    </div>
    <div class="search-item__description" :class="{ 'search-item__description--full-width': !isOnCooperativeIndex }">
      <span class="search-item__name">{{ product.name }}</span>
      <span class="search-item__supplier">{{ supplierName }}</span>
      <span class="search-item__category">{{ product.family }}</span>
    </div>
    <div class="search-item__icon">
      <span
        aria-label="Mis en avant"
        v-if="product.is_highlighted"
        class="adn-icon adn-icon--category-recommended"
        data-test-highlight-icon
      ></span>
    </div>
    <div class="search-item__icon">
      <span
        :aria-label="'Exclusivité ' + $t('message.partner')"
        v-if="product.is_exclusive"
        class="adn-icon adn-icon--category-exclusivity"
        data-test-exclusivity-icon
      ></span>
    </div>
    <div class="search-item__icon">
      <span
        aria-label="Articles Publiés"
        v-if="product.is_published"
        class="adn-icon adn-icon--status-check"
        data-test-published-icon
      ></span>
    </div>
  </router-link>
</template>

<script>
import { getProductDefaultImageFull } from '@/core/products-images.constants'

window.env = window.env || {}

export default {
  name: 'CSearchItem',
  props: ['product', 'isOnCooperativeIndex'],
  computed: {
    isACropProtectionPack() {
      return this.product && this.product.kind === 'sante_vegetal' && this.product.type === 'Pack'
    },
    imageUrl() {
      return this.product && (this.product.thumbnail || (this.product.images && this.product.images[0]))
    },
    defaultImage() {
      return getProductDefaultImageFull(this.product.kind, this.product.type)
    },
    supplierName() {
      return this.product.supplier ?? ''
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
@import '../../lib/_mixins.scss';
.search-item {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 60px;
  padding: 10px 0px 20px 10px;
  border-bottom: 2px solid var(--idf-color-neutral-200);
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: var(--idf-color-main-400);
  }
  margin-top: 10px;
  @include clearfix();
}
.search-item__image-wrapper {
  width: 58px;
  height: 58px;
  margin-left: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.search-item__image {
  max-width: 100%;
  max-height: 100%;
  background-size: 100%;
  width: 58px;
}
.search-item__description {
  display: block;
  margin-left: 10px;
  overflow: hidden;
  width: 270px;
}
.search-item__description--full-width {
  width: 100%;
}
.search-item__name {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2em;
}
.search-item__supplier {
  display: block;
  font-size: 1em;
  color: var(--idf-color-neutral-400);
  word-break: break-word;
  text-transform: uppercase;
}
.search-item__category {
  display: block;
  font-size: 0.8em;
  color: var(--idf-color-neutral-400);
  word-break: break-word;
}
.search-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  flex: 2;
  max-width: 186px;
  margin-left: 10px;
}
</style>
